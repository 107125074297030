import FundUnicefTemplate from '@/page-blocks/about-us/clear/fund/unicef/FundUnicefTemplate';
import ReportUnicef2018 from '@/page-blocks/about-us/clear/fund/unicef/report.unicef_2018';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicef2018: React.FC<PageProps> = (props) => (
  <FundUnicefTemplate year={2018} {...props}>
    <ReportUnicef2018 />
  </FundUnicefTemplate>
);

export default FundUnicef2018;
